import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "h-8 text-left pl-3" };
const _hoisted_2 = { class: "flex items-center" };
const _hoisted_3 = { class: "h-8 text-left" };
const _hoisted_4 = { class: "h-8 text-left" };
const _hoisted_5 = { class: "h-8 text-left" };
const _hoisted_6 = { class: "h-8 text-right" };
const _hoisted_7 = { class: "flex items-center justify-end" };
const _hoisted_8 = { class: "flex items-center justify-end" };
const _hoisted_9 = { class: "h-8 pr-3" };
const _hoisted_10 = { class: "flex items-center justify-end" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Header",
  setup(__props) {
    const route = useRoute();
    const { t } = useLang();
    const isSpot = route.name === "spot-spot";
    const totalTooltip = computed(
      () => isSpot ? t("trade.spotTradeHistoryTotalTooltip") : t("trade.total_tooltip")
    );
    return (_ctx, _cache) => {
      const _component_CommonInfoTooltip = __nuxt_component_0;
      return _openBlock(), _createElementBlock("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.$t("trade.time")) + " ", 1),
              _createVNode(_component_CommonInfoTooltip, {
                class: "ml-2",
                tooltip: _ctx.$t("trade.timestamp_tooltip")
              }, null, 8, ["tooltip"])
            ])
          ]),
          _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t("trade.pair")), 1),
          _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t("trade.type")), 1),
          _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t("trade.side")), 1),
          _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t("trade.price")), 1),
          _createElementVNode("th", null, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.amount")), 1),
              _createVNode(_component_CommonInfoTooltip, {
                class: "ml-2",
                tooltip: _ctx.$t("trade.amount_tooltip")
              }, null, 8, ["tooltip"])
            ])
          ]),
          _createElementVNode("th", null, [
            _createElementVNode("div", _hoisted_8, [
              _createTextVNode(_toDisplayString(_ctx.$t("trade.fee")) + " ", 1),
              _createVNode(_component_CommonInfoTooltip, {
                class: "ml-2",
                tooltip: _ctx.$t("trade.fees_tooltip")
              }, null, 8, ["tooltip"])
            ])
          ]),
          _createElementVNode("th", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.total")), 1),
              _createVNode(_component_CommonInfoTooltip, {
                class: "ml-2",
                tooltip: _unref(totalTooltip)
              }, null, 8, ["tooltip"])
            ])
          ])
        ])
      ]);
    };
  }
});
