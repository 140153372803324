import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Number/Index.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "flex items-center justify-between" };
const _hoisted_2 = { class: "font-bold text-lg" };
const _hoisted_3 = { class: "bg-gray-900 px-3 py-4 grid grid-cols-2 gap-4 text-sm" };
const _hoisted_4 = { class: "flex items-center" };
const _hoisted_5 = { class: "text-gray-500 uppercase tracking-widest text-2xs" };
const _hoisted_6 = { class: "text-right font-mono tracking-wide" };
const _hoisted_7 = { class: "text-gray-500 uppercase tracking-widest text-xs self-center" };
const _hoisted_8 = { class: "flex items-center justify-end" };
const _hoisted_9 = { class: "font-semibold uppercase ml-1" };
const _hoisted_10 = { class: "text-gray-500 uppercase tracking-widest text-xs self-center" };
const _hoisted_11 = { class: "font-semibold text-right" };
const _hoisted_12 = { class: "text-gray-500 uppercase tracking-widest text-xs self-center" };
const _hoisted_13 = { class: "text-gray-500 uppercase tracking-widest text-xs self-center" };
const _hoisted_14 = {
  key: 0,
  class: "text-right"
};
const _hoisted_15 = { class: "flex items-center" };
const _hoisted_16 = { class: "text-gray-500 uppercase tracking-widest text-2xs" };
const _hoisted_17 = {
  key: 1,
  class: "text-right"
};
const _hoisted_18 = { class: "flex items-center" };
const _hoisted_19 = { class: "text-gray-500 uppercase tracking-widest text-2xs" };
const _hoisted_20 = {
  key: 2,
  class: "text-right"
};
const _hoisted_21 = { class: "text-2xs text-gray-500" };
const _hoisted_22 = { class: "flex items-center" };
const _hoisted_23 = { class: "text-gray-500 uppercase tracking-widest text-2xs" };
const _hoisted_24 = {
  key: 3,
  class: "text-right"
};
const _hoisted_25 = { class: "text-2xs text-gray-500" };
import { TradeDirection } from "@injectivelabs/ts-types";
import {
  UI_DEFAULT_AMOUNT_DISPLAY_DECIMALS,
  UI_DEFAULT_PRICE_DISPLAY_DECIMALS
} from "@/app/utils/constants";
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "MobileTradeDetails",
  props: {
    isSpot: Boolean,
    trade: {
      type: Object,
      default: void 0
    }
  },
  setup(__props) {
    const props = __props;
    const modalStore = useModalStore();
    const isModalOpen = computed(
      () => modalStore.modals[Modal.MobileTradeDetails] && !!props.trade
    );
    const { fee, time, price, total, market, quantity, tradeExecutionType } = useTradeWithUndefined(
      computed(() => props.trade),
      computed(() => props.isSpot)
    );
    function close() {
      modalStore.closeModal(Modal.MobileTradeDetails);
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_CommonInfoTooltip = __nuxt_component_1;
      const _component_CommonTokenIcon = __nuxt_component_2;
      const _component_AppNumber = __nuxt_component_3;
      const _component_AppModal = __nuxt_component_4;
      return __props.trade && _unref(market) ? (_openBlock(), _createBlock(_component_AppModal, {
        key: 0,
        show: _unref(isModalOpen),
        sm: "",
        "onModal:closed": close
      }, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "flex items-center gap-3 cursor-pointer",
              onClick: close
            }, [
              _createVNode(_component_BaseIcon, {
                name: "arrow",
                class: "w-6 h-auto"
              }),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("trade.tradeHistoryDetails")), 1)
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("trade.time")), 1),
              _createVNode(_component_CommonInfoTooltip, {
                class: "ml-2",
                tooltip: _ctx.$t("trade.timestamp_tooltip")
              }, null, 8, ["tooltip"])
            ]),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(time)), 1),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("trade.pair")), 1),
            _createElementVNode("div", _hoisted_8, [
              _unref(market).baseToken ? (_openBlock(), _createBlock(_component_CommonTokenIcon, {
                key: 0,
                token: _unref(market).baseToken,
                sm: ""
              }, null, 8, ["token"])) : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(market).ticker), 1)
            ]),
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("trade.type")), 1),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(tradeExecutionType)), 1),
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("trade.side")), 1),
            _createElementVNode("span", {
              class: _normalizeClass(["font-semibold text-right", {
                "text-green-500": __props.trade.tradeDirection === _unref(TradeDirection).Buy,
                "text-red-500": __props.trade.tradeDirection === _unref(TradeDirection).Sell
              }])
            }, _toDisplayString(_ctx.$t(
              `trade.${__props.trade.tradeDirection === _unref(TradeDirection).Buy ? "buy" : "sell"}`
            )), 3),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("trade.price")), 1),
            _unref(price) ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
              _createVNode(_component_AppNumber, {
                decimals: _unref(market) ? _unref(market).priceDecimals : _unref(UI_DEFAULT_PRICE_DISPLAY_DECIMALS),
                number: _unref(price)
              }, null, 8, ["decimals", "number"])
            ])) : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("trade.amount")), 1),
              _createVNode(_component_CommonInfoTooltip, {
                class: "ml-2",
                tooltip: _ctx.$t("trade.amount_tooltip")
              }, null, 8, ["tooltip"])
            ]),
            _unref(quantity) ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
              _createVNode(_component_AppNumber, {
                decimals: _unref(market) ? _unref(market).quantityDecimals : _unref(UI_DEFAULT_AMOUNT_DISPLAY_DECIMALS),
                number: _unref(quantity)
              }, null, 8, ["decimals", "number"])
            ])) : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("trade.fee")), 1),
              _createVNode(_component_CommonInfoTooltip, {
                class: "ml-2",
                tooltip: _ctx.$t("trade.fees_tooltip")
              }, null, 8, ["tooltip"])
            ]),
            _unref(fee) ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
              _createVNode(_component_AppNumber, {
                "use-number-decimals": "",
                number: _unref(fee)
              }, {
                addon: _withCtx(() => [
                  _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(market).quoteToken.symbol), 1)
                ]),
                _: 1
              }, 8, ["number"])
            ])) : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t("trade.total")), 1),
              _createVNode(_component_CommonInfoTooltip, {
                class: "ml-2",
                tooltip: _ctx.$t("trade.fees_tooltip")
              }, null, 8, ["tooltip"])
            ]),
            _unref(total) ? (_openBlock(), _createElementBlock("span", _hoisted_24, [
              _createVNode(_component_AppNumber, {
                "data-cy": "trade-history-total-table-data",
                decimals: _unref(market) ? _unref(market).priceDecimals : _unref(UI_DEFAULT_PRICE_DISPLAY_DECIMALS),
                number: _unref(total)
              }, {
                addon: _withCtx(() => [
                  _createElementVNode("span", _hoisted_25, _toDisplayString(_unref(market).quoteToken.symbol), 1)
                ]),
                _: 1
              }, 8, ["decimals", "number"])
            ])) : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["show"])) : _createCommentVNode("", true);
    };
  }
});
