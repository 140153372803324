import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Number/Index.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Common/Table/Row.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex items-center justify-between col-span-2 text-xs leading-5 pb-1" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { class: "text-gray-200 font-semibold" };
const _hoisted_4 = { class: "text-gray-500 uppercase tracking-widest text-3xs" };
const _hoisted_5 = { class: "text-right" };
const _hoisted_6 = { class: "text-gray-500 uppercase tracking-widest text-3xs" };
const _hoisted_7 = { class: "text-right" };
const _hoisted_8 = { class: "text-gray-500 uppercase tracking-widest text-3xs" };
const _hoisted_9 = { class: "text-right text-xs font-mono tracking-wide" };
import { TradeDirection } from "@injectivelabs/ts-types";
import { getMarketRoute } from "@/app/utils/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "Mobile",
  props: {
    isSpot: Boolean,
    trade: {
      required: true,
      type: Object
    }
  },
  emits: ["showTradeDetails"],
  setup(__props, { emit }) {
    const props = __props;
    const {
      time,
      price,
      market,
      quantity,
      priceDecimals,
      quantityDecimals,
      tradeExecutionType
    } = useTrade(
      computed(() => props.trade),
      computed(() => props.isSpot)
    );
    const marketRoute = computed(() => {
      if (!market.value) {
        return void 0;
      }
      return getMarketRoute(market.value);
    });
    function handleShowTradeDetails() {
      emit("showTradeDetails", props.trade);
    }
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      const _component_AppNumber = __nuxt_component_2;
      const _component_CommonTableRow = __nuxt_component_3;
      return _unref(market) ? (_openBlock(), _createBlock(_component_CommonTableRow, {
        key: 0,
        dense: "",
        onClick: handleShowTradeDetails
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_NuxtLink, {
              class: "flex items-center gap-1 cursor-pointer",
              to: _unref(marketRoute)
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass({
                    "text-green-500": __props.trade.tradeDirection === _unref(TradeDirection).Buy,
                    "text-red-500": __props.trade.tradeDirection === _unref(TradeDirection).Sell
                  })
                }, _toDisplayString(_ctx.$t(
                  `trade.${__props.trade.tradeDirection === _unref(TradeDirection).Buy ? "buy" : "sell"}`
                )), 3),
                _unref(market).baseToken ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_CommonTokenIcon, {
                    token: _unref(market).baseToken,
                    sm: ""
                  }, null, 8, ["token"])
                ])) : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(market).ticker), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            _createElementVNode("span", null, _toDisplayString(_unref(tradeExecutionType)), 1)
          ]),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("trade.price")), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_AppNumber, {
              decimals: _unref(priceDecimals),
              number: _unref(price)
            }, null, 8, ["decimals", "number"])
          ]),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("trade.amount")), 1),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_AppNumber, {
              decimals: _unref(quantityDecimals),
              number: _unref(quantity)
            }, null, 8, ["decimals", "number"])
          ]),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("trade.time")), 1),
          _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(time)), 1)
        ]),
        _: 1
      })) : _createCommentVNode("", true);
    };
  }
});
