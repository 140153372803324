"use strict";
import {
  ZERO_IN_BASE
} from "@injectivelabs/sdk-ui-ts";
import { BigNumberInBase, BigNumberInWei } from "@injectivelabs/utils";
import { format } from "date-fns";
import { TradeExecutionType } from "@injectivelabs/ts-types";
import {
  UI_DEFAULT_AMOUNT_DISPLAY_DECIMALS,
  UI_DEFAULT_PRICE_DISPLAY_DECIMALS
} from "@/app/utils/constants";
export function useTrade(trade, isSpot) {
  const spotStore = useSpotStore();
  const derivativeStore = useDerivativeStore();
  const { t } = useLang();
  const market = computed(
    () => isSpot.value ? spotStore.markets.find((m) => m.marketId === trade.value.marketId) : derivativeStore.markets.find((m) => m.marketId === trade.value.marketId)
  );
  const tradeToSpotTrade = computed(() => {
    if (isSpot.value) {
      return trade.value;
    }
    const derivativeTrade = trade.value;
    return {
      ...derivativeTrade,
      price: derivativeTrade.executionPrice,
      quantity: derivativeTrade.executionQuantity,
      timestamp: derivativeTrade.executedAt
    };
  });
  const price = computed(() => {
    if (!market.value || !tradeToSpotTrade.value.price) {
      return ZERO_IN_BASE;
    }
    return isSpot.value ? new BigNumberInBase(
      new BigNumberInBase(tradeToSpotTrade.value.price).toWei(
        market.value.baseToken.decimals - market.value.quoteToken.decimals
      )
    ) : new BigNumberInWei(tradeToSpotTrade.value.price).toBase(
      market.value.quoteToken.decimals
    );
  });
  const quantity = computed(() => {
    if (!market.value || !tradeToSpotTrade.value.quantity) {
      return ZERO_IN_BASE;
    }
    return isSpot.value ? new BigNumberInWei(tradeToSpotTrade.value.quantity).toBase(
      market.value.baseToken.decimals
    ) : new BigNumberInBase(tradeToSpotTrade.value.quantity);
  });
  const total = computed(() => quantity.value.times(price.value));
  const priceDecimals = computed(
    () => market.value ? market.value.priceDecimals : UI_DEFAULT_PRICE_DISPLAY_DECIMALS
  );
  const quantityDecimals = computed(
    () => market.value ? market.value.quantityDecimals : UI_DEFAULT_AMOUNT_DISPLAY_DECIMALS
  );
  const time = computed(() => {
    if (!market.value || !trade.value.executedAt) {
      return "";
    }
    return format(trade.value.executedAt, "dd MMM HH:mm:ss");
  });
  const fee = computed(() => {
    if (!market.value || !trade.value.fee) {
      return ZERO_IN_BASE;
    }
    return new BigNumberInWei(trade.value.fee).toBase(
      market.value.quoteToken.decimals
    );
  });
  const tradeExecutionType = computed(() => {
    const derivativeTrade = trade.value;
    if (!isSpot.value && derivativeTrade.isLiquidation) {
      return t("trade.liquidation");
    }
    switch (trade.value.tradeExecutionType) {
      case TradeExecutionType.LimitFill:
        return t("trade.limit");
      case TradeExecutionType.Market:
        return t("trade.market");
      case TradeExecutionType.LimitMatchRestingOrder:
        return t("trade.limit");
      case TradeExecutionType.LimitMatchNewOrder:
        return t("trade.limit");
      default:
        return t("trade.limit");
    }
  });
  return {
    fee,
    time,
    price,
    total,
    market,
    quantity,
    priceDecimals,
    quantityDecimals,
    tradeExecutionType
  };
}
export function useTradeWithUndefined(trade, isSpot) {
  if (trade.value) {
    return useTrade(trade, isSpot);
  }
  return {
    fee: void 0,
    time: void 0,
    price: void 0,
    total: void 0,
    market: void 0,
    quantity: void 0,
    priceDecimals: void 0,
    quantityDecimals: void 0,
    tradeExecutionType: void 0
  };
}
