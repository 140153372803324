import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Number/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["data-cy"];
const _hoisted_2 = { class: "h-12 text-left pl-3" };
const _hoisted_3 = {
  class: "text-white text-xs",
  "data-cy": "trade-entry-time"
};
const _hoisted_4 = { class: "h-12 text-left cursor-pointer" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "ml-3" };
const _hoisted_7 = {
  class: "text-gray-200 text-xs",
  "data-cy": "trade-history-ticker-name-table-data"
};
const _hoisted_8 = {
  class: "h-12 text-left",
  "data-cy": "trade-history-execution-type-table-data"
};
const _hoisted_9 = { class: "text-white text-xs" };
const _hoisted_10 = { class: "h-12 text-left" };
const _hoisted_11 = { class: "h-12 text-right font-mono" };
const _hoisted_12 = { class: "h-12 text-right font-mono" };
const _hoisted_13 = { class: "h-12 text-right font-mono" };
const _hoisted_14 = { class: "text-xs text-gray-500" };
const _hoisted_15 = { class: "h-12 text-right font-mono pr-3" };
const _hoisted_16 = { class: "text-xs text-gray-500" };
import { TradeDirection } from "@injectivelabs/ts-types";
import { getMarketRoute } from "@/app/utils/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "Row",
  props: {
    isSpot: Boolean,
    trade: {
      required: true,
      type: Object
    }
  },
  setup(__props) {
    const props = __props;
    const {
      fee,
      time,
      price,
      total,
      market,
      quantity,
      priceDecimals,
      quantityDecimals,
      tradeExecutionType
    } = useTrade(
      computed(() => props.trade),
      computed(() => props.isSpot)
    );
    const marketRoute = computed(() => {
      if (!market.value) {
        return void 0;
      }
      return getMarketRoute(market.value);
    });
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      const _component_AppNumber = __nuxt_component_2;
      return _unref(market) ? (_openBlock(), _createElementBlock("tr", {
        key: 0,
        "data-cy": "trade-history-table-row-" + _unref(market).ticker
      }, [
        _createElementVNode("td", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(time)), 1)
        ]),
        _createElementVNode("td", _hoisted_4, [
          _createVNode(_component_NuxtLink, {
            class: "flex items-center justify-start",
            to: _unref(marketRoute)
          }, {
            default: _withCtx(() => [
              _unref(market).baseToken ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_CommonTokenIcon, {
                  token: _unref(market).baseToken,
                  md: ""
                }, null, 8, ["token"])
              ])) : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(market).ticker), 1)
              ])
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("td", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(tradeExecutionType)), 1)
        ]),
        _createElementVNode("td", _hoisted_10, [
          _createElementVNode("span", {
            "data-cy": "trade-history-trade-directon-table-data",
            class: _normalizeClass(["text-xs", {
              "text-green-500": __props.trade.tradeDirection === _unref(TradeDirection).Buy,
              "text-red-500": __props.trade.tradeDirection === _unref(TradeDirection).Sell
            }])
          }, _toDisplayString(_ctx.$t(
            `trade.${__props.trade.tradeDirection === _unref(TradeDirection).Buy ? "buy" : "sell"}`
          )), 3)
        ]),
        _createElementVNode("td", _hoisted_11, [
          _createVNode(_component_AppNumber, {
            xs: "",
            "data-cy": "trade-history-price-table-data",
            decimals: _unref(priceDecimals),
            number: _unref(price)
          }, null, 8, ["decimals", "number"])
        ]),
        _createElementVNode("td", _hoisted_12, [
          _createVNode(_component_AppNumber, {
            xs: "",
            "data-cy": "trade-history-quantity-table-data",
            decimals: _unref(quantityDecimals),
            number: _unref(quantity)
          }, null, 8, ["decimals", "number"])
        ]),
        _createElementVNode("td", _hoisted_13, [
          _createVNode(_component_AppNumber, {
            xs: "",
            "use-number-decimals": "",
            number: _unref(fee),
            "data-cy": "trade-history-fee-table-data"
          }, {
            addon: _withCtx(() => [
              _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(market).quoteToken.symbol), 1)
            ]),
            _: 1
          }, 8, ["number"])
        ]),
        _createElementVNode("td", _hoisted_15, [
          _createVNode(_component_AppNumber, {
            xs: "",
            "data-cy": "trade-history-total-table-data",
            decimals: _unref(priceDecimals),
            number: _unref(total)
          }, {
            addon: _withCtx(() => [
              _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(market).quoteToken.symbol), 1)
            ]),
            _: 1
          }, 8, ["decimals", "number"])
        ])
      ], 8, _hoisted_1)) : _createCommentVNode("", true);
    };
  }
});
